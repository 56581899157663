<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="targetItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="targetYn"
            label="올해점검대상"
            v-model="searchParam.targetYn"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년도 -->
          <c-datepicker
            label="점검년도"
            name="year"
            type="year"
            v-model="searchParam.year">
          </c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip-class
            :editable="editable"
            type="search"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckKindCd"
            label="점검유형"
            v-model="searchParam.equipmentCheckKindCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_CYCLE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="inspectionCycleCd"
            label="법정점검주기"
            v-model="searchParam.inspectionCycleCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="MDM_EQUIP_CYCLE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="selfCycleCd"
            label="자체점검주기"
            v-model="searchParam.selfCycleCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="설비등급"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비등급 및 점검계획표"
      tableId="table"
      :columns="grid.columns"
      :checkClickFlag="false"
      :data="grid.data"
      rowKey="equipmentCd"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="계획등록" v-if="editable" icon="add" @btnClicked="onItemClick('A')" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-inspection',
  data() {
    return {
      grid: {
        // merge: [
        //   { index: 0, colName: 'plantName' },
        //   { index: 2, colName: 'upEquipmentTypeName' },
        //   { index: 3, colName: 'equipmentTypeName' },
        // ],
        columns: [
          {
            name: 'upEquipmentTypeName',
            field: 'upEquipmentTypeName',
            label: '대분류',
            style: 'width:200px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '중분류',
            style: 'width:170px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'equipmentCdName',
            field: 'equipmentCdName',
            label: '설비명(관리번호)',
            style: 'width:250px',
            align: 'left',
            sortable: false,
            fix: true,
            type: "link",
          },
          {
            name: 'estimationScore',
            field: 'estimationScore',
            label: '평가점수',
            style: 'width:40px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'gradeName',
            field: 'gradeName',
            label: '위험등급',
            style: 'width:45px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px', 
            sortable: false,
            // rowspan: 5,
          },
           {
            name: 'targetYn',
            field: 'targetYn',
            label: '올해법정점검대상',
            align: 'center',
            style: 'width:72px', 
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'blue' },
              { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'blue-grey-4' },
              // { stepperMstCd: 'NE', stepperMstNm: '완료된점검없음', colorClass: 'blue-grey-4' },
            ],
            sortable: false,
            // rowspan: 5,
          },
          {
            label: '점검일정',
            align: 'center',
            style: 'width:360px', 
            sortable: false,
            child: [
              {
                name: 'mon01',
                field: 'mon01',
                label: '1월',
                align: 'center',
                style: 'width:50px', 
                sortable: false,
                type: "link",
              },
              {
                name: 'mon02',
                field: 'mon02',
                label: '2월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon03',
                field: 'mon03',
                label: '3월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon04',
                field: 'mon04',
                label: '4월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon05',
                field: 'mon05',
                label: '5월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon06',
                field: 'mon06',
                label: '6월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon07',
                field: 'mon07',
                label: '7월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon08',
                field: 'mon08',
                label: '8월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon09',
                field: 'mon09',
                label: '9월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon10',
                field: 'mon10',
                label: '10월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon11',
                field: 'mon11',
                label: '11월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon12',
                field: 'mon12',
                label: '12월',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
            ]
          },
          {
            label: '법정점검',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'recentInspectionDate',
                field: 'recentInspectionDate',
                label: '최근일',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'inspectionRequestDate',
                field: 'inspectionRequestDate',
                label: '필요일',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'inspectionCycleName',
                field: 'inspectionCycleName',
                label: '주기',
                style: 'width:60px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            label: '자체점검',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'recentSelfDate',
                field: 'recentSelfDate',
                label: '최근일',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'selfRequestDate',
                field: 'selfRequestDate',
                label: '필요일',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'selfCycleName',
                field: 'selfCycleName',
                label: '주기',
                style: 'width:60px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            label: '점검자',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'checkDeptNames',
                field: 'checkDeptNames',
                label: '부서',
                style: 'width:100px', 
                align: 'left',
                sortable: false,
              },
              {
                name: 'checkUserNames',
                field: 'checkUserNames',
                style: 'width:150px', 
                label: '점검자',
                align: 'left',
                sortable: false,
              },
            ]
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:200px', 
            sortable: false,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'left',
            style: 'width:150px', 
            sortable: false,
          },
          {
            label: '정비계획',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'cmFlagName',
                field: 'cmFlagName',
                style: 'width:80px', 
                label: '고장정비',
                align: 'center',
                sortable: false,
              },
              {
                name: 'preventCycleName',
                field: 'preventCycleName',
                style: 'width:100px', 
                label: '예방정비',
                align: 'center',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      // 최근법정, 최근자체 제거
      searchParam: {
        plantCd: '',
        year: '',
        equipmentCheckKindCd: null,
        relatedLawList: [],
        equipmentTypeCd: '',
        equipmentCd: '',
        inspectionCycleCd: null,
        selfCycleCd: null,
        targetYn: null,
      },
      targetItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '비대상' },
      ],
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {      
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.min.equipment.annual.list.url;
      // code setting
      this.searchParam.year = this.$comm.getThisYear();
      // list setting
      this.getList();
    },
    getList() {
      // this.searchParam.recentStartSelfDate = this.recentSelfDates[0];
      // this.searchParam.recentEndSelfDate = this.recentSelfDates[1];
      // this.searchParam.recentStartInspectionDate = this.recentInspectionDates[0];
      // this.searchParam.recentEndInspectionDate = this.recentInspectionDates[1];
     // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.equipmentPopup(row, col, true);
    },
    onItemClick() {
      let selectData = this.$refs['table'].selected;

      let title = '';
      let param = null;
      param = {
        title: "연간점검계획",
        selectData: selectData ? selectData: [],
        equipmentCd: '',
        checkDate:  '',
      };
      title = '연간점검계획 상세';
      this.popupOptions.target = () =>
      import(`${"./equipmentPlan.vue"}`);
      
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    equipmentPopup(result, col, flag) {
      let title = '';
      let param = null;
      if (col && col.name === 'equipmentCdName') {
        param = {
          equipmentCd: result ? result.equipmentCd : '',
        };
        title = '설비마스터 상세';
        this.popupOptions.target = () =>
          import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
        this.popupOptions.isFull = true;
      } else {
         param = {
          title: "연간점검계획",
          selectData: [],
          plantCd: result.plantCd,
          equipmentCd: result.equipmentCd,
          checkDate:  flag ? this.searchParam.year + '-' + col.name.substring(3) : '',
        };
        title = result.equipmentCdName + '의 ' +  this.searchParam.year + '년 ' + 
          col.name.substring(3) + '월 점검계획 상세';
        this.popupOptions.target = () =>
          import(`${"./equipmentPlan.vue"}`);
      }
      
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  }
};
</script>